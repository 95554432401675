/* iconify custom properties */

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.icon {
  width: unset;
  height: unset;

  &.spin {
    animation: spin 1s linear infinite;
  }

  &.circle {
    border-radius: 50%;
  }

  &.xs {
    font-size: 0.75rem;
  }

  &.sm {
    font-size: 0.875rem;
  }

  &.lg {
    font-size: 1.33rem;
  }

  &.xl {
    font-size: 1.5rem;
  }

  &.s2 {
    font-size: 2rem;
  }

  &.s3 {
    font-size: 3rem;
  }

  &.s4 {
    font-size: 4rem;
  }

  &.s5 {
    font-size: 5rem;
  }

  &.s6 {
    font-size: 6rem;
  }

  &.s7 {
    font-size: 7rem;
  }

  &.s8 {
    font-size: 8rem;
  }

  &.s9 {
    font-size: 9rem;
  }

  &.s10 {
    font-size: 10rem;
  }

  &.border {
    border: $secondary solid 0.1em !important;
    border-radius: 50%;
    padding: 0.1em;

    svg {
      margin: 0;
      vertical-align: bottom;
    }
  }
}

svg {
  margin: 0;
  vertical-align: bottom;
}
