#navbar {
  .sign-title {
    font-size: font-size(30px);
    font-weight: 600;
    color: #34434e;
    text-align: center;
  }

  .sign-desc {
    font-size: font-size(17px);
    text-align: center;
    color: #8997a2;
  }

  .text-user-dropdown {
    font-size: font-size(14px);
    color: #8997a2;
    line-height: 0;
    align-items: baseline;
    display: flex;
    cursor: pointer;
  }

  .drop-profile {
    background: #ffffff;
    border: 1px solid #dce1e6;
    box-sizing: border-box;
    border-radius: 5px;

    padding: 10px;
  }

  .circular-avatar-navbar {
    width: 44px !important;
    height: 44px !important;
    background-color: #ffffff;
    cursor: pointer;
  }

  .default-circular-avatar-navbar {
    width: 44px !important;
    height: 44px !important;
    background-color: #ffffff;
    cursor: pointer;
  }

  .navbar-circular-button {
    display: flex;
    justify-content: center;
    align-content: center;

    color: #fff;
    background: #18a0fb;
    padding: 10px;
    width: 44px;
    height: 44px;
    border: 1px solid #18a0fb;
    box-sizing: border-box;
    border-radius: 50%;
  }

  .dropdown-toggle::after {
    display: none !important;
  }

  .dropdown-item {
    padding: 0 !important;
    margin-left: 10px;
  }

  .modal-footer {
    display: block !important;
    font-weight: 500;
  }

  .modal-header {
    display: block !important;
  }

  .header-container {
    align-items: center;
    display: flex;
    position: relative;

    .header-itens {
      align-items: center;
      margin: 0 auto;
      position: relative;
      // max-width: 1360px;
      width: 100%;

      &.row {
        margin-left: auto;
        margin-right: auto;
      }

      .header-logo {
        img {
          height: 48px;

          &.header-logo-img-large {
            padding-top: 10px;
            padding-bottom: 10px;
          }
        }
      }
    }
  }

  .header-logo.header-logo {
    margin-left: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    width: fit-content;

    &.center-logo {
      margin: 0 auto;
    }
  }

  .header-search-box.header-search-box {
    max-width: 644px;
    width: calc(100% - 450px);
    margin-right: auto;
  }

  .header-search-icon {
    height: 50px;
  }

  .header-right-buttons.header-right-buttons {
    margin-left: 0px;
    margin-top: 0px;

    button,
    a {
      height: 100%;
      width: 100%;
      padding: 0;
    }

    .col {
      width: fit-content;
    }

    .row {
      margin-left: -1.5rem;
      margin-right: -1.5rem;
    }
  }

  .header-right-container {
    width: 256px !important;
  }

  .home-process-input-group {
    width: 100%;
    height: 50px;
    margin: auto;
    padding: 0px;
    background: #f1f3f4 !important;
    border-radius: 10px;
  }

  .home-title-text {
    font-size: 2rem;
  }

  .home-subtitle-text {
    font-weight: 300;
    display: inline-block;
  }

  .form-control-background {
    background: #f1f3f4 !important;
    width: 30rem;
  }

  .language-text {
    position: static;
    left: 16.95%;
    right: 55.93%;
    top: 22.73%;
    bottom: 22.73%;
    /* Inline text element / p */

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    /* gray 500 */

    color: #8997a2;

    /* Inside Auto Layout */

    flex: none;
    order: 0;
    align-self: center;
  }

  .svg-language-icon {
    margin-block-end: auto;
    margin-bottom: 2px;
    margin-left: 1vh;
  }

  .svg-search-icon {
    margin: auto;
    opacity: 0.5;
  }

  .language-dropdown {
    width: 42px;
    font-size: 14px !important;
    padding: 0px;
    background: unset;
    border: 0px;

    &::before {
      width: 25px;
      display: none;
    }

    &:focus {
      box-shadow: none;
    }

    option {
      color: #9aa1a6;
      padding-right: 0 !important;
    }
  }

  .placeholder-text {
    min-width: 200px !important;
    width: 100%;
    font-family: Inter !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 13px !important;
    line-height: 16px !important;
    color: #34434e !important;
    opacity: 0.5;
    background: #f1f3f4;
  }

  #searchApp {
    padding: 0.75rem 1rem 0.975rem 1rem !important;
  }

  .upload-icon-header {
    margin-left: 1px;
  }
}
