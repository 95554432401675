// font
$min-font-size: 14px;
$max-font-size: 18px;
$min-viewport-width: 320px;
$max-viewport-width: 1024px;
$linear-coefficient: ($max-font-size - $min-font-size) / ($max-viewport-width - $min-viewport-width);

%font {
    font-size: calc(#{$min-font-size} + (100vw - #{$min-viewport-width}) * #{$linear-coefficient});
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25;
    color: #34434e;
}

@function font-size($size) {
    $base: $size / $max-font-size;
    @return calc(#{$base} * 1rem);
}

:root, body {
    @extend %font;
    text-align: start;
}

@media screen and (max-width: $min-viewport-width) {
    :root, body {
        font-size: $min-font-size;
    }
}

@media screen and (min-width: $max-viewport-width) {
    :root, body {
        font-size: $max-font-size;
    }
}

// headers
%headers {
    @extend %font;
    font-weight: 600;
}

@mixin h1 {
    @extend %headers;
    font-size: 1.5rem;
}

@mixin h2 {
    @extend %headers;
    font-size: 1.25rem;
}

@mixin h3 {
    @extend %headers;
    font-size: 1rem;
}

@mixin h4 {
    @extend %headers;
    font-size: 0.9rem;
}

@mixin h5 {
    @extend %headers;
    font-size: 0.8rem;
}

@mixin h6 {
    @extend %headers;
    font-size: 0.7rem;
}

h1 {
    @include h1;
}

h2 {
    @include h2;
}

h3 {
    @include h3;
}

h4 {
    @include h4;
}

h5 {
    @include h5;
}

h6 {
    @include h6;
}
