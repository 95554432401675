.resources-home {
  #navbar + hr {
    margin: 0px 0px 0.5rem 0px;
  }

  .search-section {
    button {
      font-size: 0.85rem;
      font-weight: 500;
    }
  }
}
