#search {
    .link-see-more {
        @include h4;
        color: #18a0fb;
        margin: auto;
        display: block;
        width: fit-content;
    }

    .most-recents-text {
        @include h4;

        display: flex;
        align-items: center;
    }
}