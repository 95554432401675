#review {

    .star-ratings {
        margin-bottom: 10px;
    }

    .send-btn {
        height: 48px;
        border: 1px solid transparent;
        background: #18a0fb;
        border-radius: 5px;
        padding: 0px 15px;

        font-weight: 500;
        font-size: font-size(15px);
        color: #ffffff;
    }

    .trashIcon{
        margin-left: auto;

        cursor: pointer;
    }

    .author-name {
        /* Heading / h6 */
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        /* identical to box height, or 110% */
        /* Dark */
        color: #34434e;
    }

    .review-wrapper {
        width: auto;
        margin: auto;
    }

    .reviews-container {
        align-items: center;
        display: grid;
        position: relative;

        width: 100%;
    }

    .reviews-option-filter {

        .text-f {
            @include h1;
        }
    }

    .circular-avatar-review {
        width: 50px !important;
        height: 50px !important;
        background-color: #ffffff;

        button {
            background-color: #ffffff;
        }
    }

    .review-area {
        width: 100%;
    }

    .review-stars-container {
        align-items: baseline;
        display: flex;
    }

    .review-stars-item {
        margin-left: 1vh;
    }

    .review-link-see-more-container {
        width: 100%;
    }

    .review-background {
        background: #ffffff;
    }

    // Após inserir seção de comentários poderá remover esse código daqui, pois o mesmo já está no styles do review.  
    .review-wrapper{
        width: auto;
        margin: auto;
    }

    .textAssessment{
        span {
            font-size: font-size(14px);
            color: #8997a2;
        }
    }

    select {
        &.main-drop {
            width: min-content;
        }
    }
}
