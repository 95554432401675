#more-information {
    font-size: 0.85rem;

    .mi-title {
        text-align: center;
        font-size: 2rem;
        margin-top: 112px;
    }

    .mi-sub-title {
        @include h3; // Não usar elemento h4 por causa de seo
        text-align: center;
        font-weight: 300;
        color: #8997a2;
        margin: 0 auto 60px;
    }

    .mi-blue-title {
        font-weight: 500;
        color: #18a0fb;
        @extend .mb-3
    }

    .mi-description {
        color: #8997a2;
        font-size: 0.9em;
    }

    .mi-card {
        text-align: left;
    }

    .download-button-home {
        border: 1px solid transparent;
        background: #18a0fb;
        border-radius: 5px;
        margin: 60px auto 112px;
        display: flex;
        flex-direction: row;
    }

    .download-button-home:hover {
        transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
        transform: scale(1.01, 1.01);
        box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
    }

    .download-text {
        font-weight: 500;
        color: #ffffff;
    }
}
