#description {
    .description-body {
        color: #8997a2;

        h2 {
            font-weight: 500;
            margin-bottom: 1.2rem;
            color: $secondary;
        }

        p {
            margin-bottom: 1.5rem;
        }
    }

    .text-f {
        font-weight: 500;
        font-size: font-size(15px);
        margin-bottom: 1.2rem;
    }

    .description-background {
        padding-top: 38px;
    }

    .referred-process-link {
      font-size: 0.75rem;
    }
}
