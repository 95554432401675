// Example:

// visible
// visible-sm
// visible-md
// visible-lg
// visible-xl

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .visible#{$infix} {
            visibility: visible !important;
        }
        .invisible#{$infix} {
            visibility: hidden !important;
        }
    }
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $position in $positions {
            .p-#{$position}#{$infix} {
                position: $position !important;

                @if ($position == sticky) {
                    &-top {
                        @extend .p-#{$position}#{$infix};
                        top: 0;
                        z-index: $zindex-sticky;
                    }
                }
            }
        }
    }
}

@each $size, $length in $spacers {
    @if $size != 0 {
        .flex-gap-column-#{$size} {
            $gap: $length;
            display: flex;
            flex-wrap: wrap;
            margin: (-$gap) 0 0 0 !important;
            width: 100%;

            & > * {
                margin: $gap 0 0 0 !important;
            }
        }
    }
}
// @each $breakpoint in map-keys($grid-breakpoints) {
//     @include media-breakpoint-up($breakpoint) {
//         $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
//     }
// }
