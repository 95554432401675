#process {
  .content-background  {
    background: linear-gradient(180deg, #ffffff00 0%, #ffffff 50%,  #ffffff 100%), #fbfcfd;
  }

  hr {
    margin: 0px;
  }

  .process-card {
    top: 1rem;
  }

  .download-text {
    font-weight: 500;
    color: #ffffff;
  }

  .download-button-home {
    border: 1px solid transparent;
    background: #18a0fb;
    border-radius: 5px;
    margin: 60px auto 112px;
    display: flex;
    flex-direction: row;
  }

  .download-button-home:hover {
      transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
      transform: scale(1.01, 1.01);
      box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }

}
