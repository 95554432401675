.mde.mde {
    .cm-header {
        font-size: unset;
        line-height: unset;
        font-weight: unset;
        
        &.cm-header-2 {
            font-size: 160%;
            line-height: 160%;
            font-weight: 700;
        }
    }

    .cm-link,
    .cm-url,
    .cm-tag,
    .cm-attribute,
    .cm-quote,
    .cm-string
    {
        color: unset;
    }    

    .cm-quote {
        font-style: normal;
    }

    .cm-comment {
        background: transparent;
        border: 0px;
    }

    .cm-link {
        text-decoration: unset;
    }
}