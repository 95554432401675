#author {
    .link-see-more {
        @include h4;
        color: #18a0fb;
        margin: auto;
        display: block;
        width: fit-content;
    }

    .most-recents-text {
        @include h4;

        display: flex;
        align-items: center;
    }

    .author-info {
        .author-image {
            width: 44px;
            height: 44px;
        }

        .avatar {
            width: 90px;
            height: 90px;

            padding: 18px;
            border: 2px solid #34434e;
            border-radius: 50%;

            .Streamline_Icon  {
                line-height: 50px;
                display: block;
            }
        }

        h4 {
            margin: 0px;
            margin-left: 20px;
        }
    }
}
