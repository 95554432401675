#process-navbar {
    .header-process {
        width: 100%;
        // align-items: center;
        // z-index: auto;
        // border-block-end: 1px solid #F1F3F4;
        background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #FBFCFD;
        z-index: 1;
      }
      
      .header-process-page {
        width: 100%;
        // align-items: center;
        // z-index: auto;
        border-block-end: 1px solid #F1F3F4;
        background: #FFFFFF;
      }
      
      .header-process-default {
        margin: 0 auto;
        height: 100%;
        overflow-x: hidden;
      }
      
      .header-process-background {
        background: #FFFFFF;
      }
      
      .header-process-breadcrumbs {
        display: flex;
        max-height: 29px;
        margin-bottom: 5px;
        color: #18A0FB;
      }
      
      .header-process-breadcrumb-left-item {
         margin-right: 12px;
      }
      
      .header-process-breadcrumb-right-item {
         margin-left: 12px;
      }
      
      .numb {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 19px;
        display: flex;
        align-items: center;
        color: #8997A2;
        margin-left: 0.5rem;
      }
      
      .follow-text {
        margin-right: 10px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 19px;
        text-align: right;
        color: #34434E;
        align-self: center;
      }
      
      .object-text {
        // @include h3;
        // color: #34434E;
        
        // Configura quebra de linha com reticências caso título seja muito grande
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-width: 100%;
      }
      
      .header-process-breadcrumbs-text {
        /* Home */
        position: relative;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 19px;
        color: #18A0FB;
        /* Inside Auto Layout */
      }
      
      .contorn-button {
        border: 1px solid #DCE1E6;
        box-sizing: border-box;
        border-radius: 5px;
        height: 36px;
      }
      
      .header-process-info-itens{
        display: flex;
        // min-width: 55px;
        // max-width: 75px;
        // margin-top: 0.5rem !important;
        // padding-top: 7px;
      }
      
      .header-process-info-itens-follow{
        width: fit-content;
        display: flex;
        padding: 0 10px;
        height: 36px;
      }
      
      .header-process-info-itens-share{
        display: flex;
        padding: 0 10px;
        height: 36px;
      }
      
      .share-icon {
        margin: auto;
        line-height: 1.15;
      
        &+.share-icon {
          margin-left: 2px;
        }
      }
}