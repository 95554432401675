#popover-share-buttons {
    box-shadow: none;
    border: 1px solid #f1f3f4;
    box-sizing: border-box;
    border-radius: 5px;

    .share-icon {
        display: block;
        width: 100%;

        a {
            color: #34434e;
        }

        svg {
            height: 1em;
            margin: 0px;
            margin-right: 0.3em;
        }

        .icon {
            vertical-align: middle;
        }

        & + .share-icon {
            margin-top: 0.5em;
        }
    }
}
