#categories {
  .header-category {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .header-background {
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #FBFCFD;
  }

  .link-see-more {
    @include h4;
    color: #18a0fb;
    margin: auto;
    display: block;
    width: fit-content;
  }

  .most-recents-text {
    @include h2;

    display: flex;
    align-items: center;
  }

  .processes-background {
    margin-top: 60px;
    background:#fbfcfd;
  }

  .process-view-header-container {
    margin-top: 30px;
  }

  .more-information-background {
    background: linear-gradient(180deg, #fbfcfd 0%, rgba(255, 255, 255, 0) 100%), #ffffff;
  }
}
