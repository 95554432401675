.download-button {
    background: $primary;
    border-radius: 5px;
    border: 1px solid transparent;
    font-weight: 500;
    font-size: 0.9rem;
    svg {
        vertical-align: initial;
    }
}
