.resources-post {
    .post-content {
        color: #8997a2;

        h1, h2, h3, h4, h5, h6 {
            font-weight: 500;
            margin-bottom: 1.2rem;
            color: $secondary;
        }

        p {
            margin-bottom: 1.5rem;
        }

        blockquote {
            p {
                margin-bottom: 0px;
            }
        }

        img {
            max-width: 100%;
        }
    }
}