#upload-diagram-process {
  .upload-background {
    border-block-start: 1px solid #f1f3f4;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%), #fbfcfd;
  }

  .upload-form-button {
    margin: 1rem 0 !important;
    font-weight: 500;
    font-size: font-size(15px);

    span > span {
      margin-bottom: 0.5rem;
    }
  }

  .add-remove-item-button {
    span > span {
      margin-bottom: 0.5rem;
    }
  }

  .form-field-select {
    margin-right: 0 !important;
  }

  #uploadForm {
    padding-bottom: 7%;
  }

  .input-group {


    & > .input-group-append > .input-group-text {
      padding: 0px;

      & > button {
        margin: auto;
        padding: 11.25px;
        display: flex;
      }
    }
  }

  .contents {
    .input-group {
      input {
        border-bottom-left-radius: 0px;
      }

      .input-group-text {
        border-bottom-right-radius: 0px;
      }
    }

    textarea {
      margin-top: -0.0625rem;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
  }

  label[for="processFileUploadInput"] {
    svg {
      display: inline-block;
      margin: auto;
      font-size: 1.25em;
      vertical-align: sub;
      margin-right: 0.5em;
    }
  }

  .upload-card {
    .card {
      box-shadow: none;
      border: 1px solid #f1f3f4;
      box-sizing: border-box;
      border-radius: 5px;
    }

    .author-box {
      img {
        width: 2.75rem;
        height: 2.75rem;
        margin-right: 1rem;
      }
    }

    .last-update {
      .date {
        color: #97a4af;
      }
    }
  }

  .upload-images {
    .card {
      box-shadow: none;
      border: 1px solid #f1f3f4;
      box-sizing: border-box;
      border-radius: 5px;

      .no-image {
        height: 60vh;
        display: flex;
        justify-content: center;
        
        .edit-process-button {
          font-size: 1.75rem;
          margin-bottom: 0;
        }
      }
      .card-img-overlay {
        .edit-process-button {
          background-color: #ffffff;
          .Streamline_Icon {
            margin-right: 0;
          }
        }
      }
    }

  }

  .is-invalid {
    @extend .form-control.is-invalid;
  }

  .submit-button {
    font-weight: 700;
    font-size: 1.0rem;
    color: #ffffff;
    .Streamline_Icon {
      margin-right: 7px;
    }
  }

  .save-draft-button {
    font-weight: 500;
    font-size: 0.8rem;
    margin-bottom: 0;
    .Streamline_Icon {
      margin-right: 5px;
    }
  }

  .edit-process-button{
    font-weight: 500;
    font-size: 0.9rem;
    &:hover {
      color: #34434e;
      background-color: #ffffff;
      border-color: #f1f3f4;
    }
    .Streamline_Icon {
      margin-right: 7px;
    }
  }

  .remove-button {
    font-weight: 500;
    color: #dc3545;
    font-size: 0.8rem;
    .Streamline_Icon {
      margin-right: 5px;
    }
  }

  tags.tagify {
    min-height: calc(1.5em + 1.375rem);
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.25rem;

    font-weight: 400;
    color: #1e2022;
    background-color: #fff;
    background-clip: padding-box;
    border: 0.0625rem solid #e7eaf3;
    transition: border-color 0.15s;

    margin: 0px;
    padding-left: calc(1.125rem - 5px);

    padding-top: 2.5px;
    padding-bottom: 2.5px;

    .tagify__tag {
      margin: 2.5px;
    }

    .tagify__input {
      margin-top: auto;
      margin-bottom: auto;
      color: #1e2022;
    }
  }
}
