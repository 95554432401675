.process-grid.grid {
    display: grid;
    column-gap: 1rem;
    row-gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    grid-template-rows: repeat(auto-fit, 390px);
    /* justify-content: space-evenly; */
    justify-content: space-between;

    .card-link {
        margin: 0px;

        .process-card {
            display: grid;
            grid-template-rows: 65% 25% 10%;

            width: 100%;
            height: 390px;

            min-width: 290px;
            max-width: 370px;

            background-color: white;
            border: 1px solid #f1f3f4;
            box-shadow: none;
            transition: 0.5s;
            border-radius: 5px;

            cursor: pointer;

            .card-footer-container {
                border-block-start: 1px solid #f1f3f4;
                &.card-body {
                    padding: 0px;
                }
            }

            .card-subtitle .badge {
                position: absolute;
                right: 1rem;
            }
        }
    }
}

#process-view {
    .without-process-text {
        @include h3;
        width: 100%;
        text-align: center;
        align-self: center;
    }

    .process-grid.grid {
        @extend .process-grid, .grid;

        .card-link {
            .process-card {
                .card-img {

                    @media (min-width: 576px) {
                        min-width: 368px;
                    }

                    @media (min-width: 768px) {
                        min-width: 334.583px;
                    }

                    @media (min-width: 992px) {
                        min-width: 309.417px;
                    }

                    @media (min-width: 1200px) {
                        min-width: 368px;
                    }
                }

            }
        }
    }
}

.Streamline_Icon {
    &.middle {
        svg {
            vertical-align: middle;
        }
    }
}
