#process-view {
    .related-text {
        @include h1;
    }

    .most-recents-text {
        @include h1;
    }

    .related-processes {
        background: #fbfcfd;
    }

    .process-card {
        background-color: white;
        border: 1px solid #f1f3f4;
        box-shadow: none !important;
        display: grid;
        
        cursor: pointer;
        transition: 0.5s;
        border-radius: 5px;
        width: 100%;
    }

    .process-card:hover {
        transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
        transform: scale(1.01, 1.01);
        box-shadow: 0 0.125rem 1.25rem rgba(0, 0, 0, 0.175) !important;
    }

    .proc-text.proc-text {
        font-weight: 600;
        font-size: 1.1rem;
        margin-bottom: 3px;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
    }

    .pac-text.pac-text {
        font-weight: 500;
        color: #8997a2;
        margin-top: 0px;
    }

    .card-footer-proc {
        display: flex;
        flex-wrap: wrap;
        border-top-color: rgb(220, 225, 230);
        border-top-style: solid;
        border-top-width: 1px;
        padding: 0 !important;
    }

    .assessment {
        height: 20px !important;
        max-height: 20px !important;
    }

    .card-footer-proc-text {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 19px;
        display: flex;
        color: #8997a2;
        margin-left: 6.5px;
    }

    .card-footer-container {
        width: 100%;
        margin-left: 0 !important;
    }

    .stars {
        margin: 1.25px;
        width: 11.87px;
        height: 12.5px;
    }

    .process-view {
        width: 100%;
        margin: auto;
        overflow: hidden;
    }

    .process-view-header-container {
        margin-top: 60px;
        margin-bottom: 40px;
        position: relative;
        width: auto;

        &.row {
            margin-left: 0px;
            margin-right: 0px;

            [class*="col-"] {
                padding: 0px;
            }
        }
    }

    .process-view-option-filter {
        margin-left: auto;
    }

    .process-view-option-filter-icon-box {
        margin-left: auto;
    }

    .svg-option-filter-icon {
        margin-block-end: auto;
    }

    .main-drop {
        font-family: Poppins !important;
        float: right;
        background: #ffffff;
        border: 1px solid #dce1e6;
        box-sizing: border-box;
        border-radius: 5px;
        height: 31.65px;
        align-items: baseline;
        padding: 0 14px;
        font-size: 13px !important;

        &::before {
            display: none;
        }

        div {
            overflow: visible;
        }

        > svg {
            margin-right: 6px;
        }
    }

    .form-control-main-drop {
        option {
            color: #9aa1a6;
        }
    }
    

    .drop-text {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 19px;
        color: #34434e;
        opacity: 0.5;
        /* identical to box height */
        margin-top: 3px;
        text-align: left;

        /* Dark */

        color: #34434e;
        opacity: 0.5;
        -webkit-appearance: none; /* Remove estilo padrão do Chrome */
        -moz-appearance: none; /* Remove estilo padrão do FireFox */
        background: none;
        border: none;
    }

    .drop-text-show {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 19px;
        color: #34434e;
        opacity: 0.5;
        opacity: 0.5;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: none;
        border: none;
        width: 171px;
        height: 37.65px;
        text-align: left;
    }

    .drop-text-show:hover {
        background: #34434e;
        color: #ffffff;
    }

    ul {
        padding: 0 !important;
    }

    .MenuItem.dropdownProcess {
        min-width: 167px;
    }

    .process-view-card-image {
        width: 100%;
        height: 100%;
        object-fit: fill;
        background-size: cover;
        background-position: center;
    }

    .process-view-card-no-image {
        background-color: #f1f3f4;
        color: #34434e;
    }

    .process-view-cards-container {
        a:hover {
            text-decoration: none !important;
        }
    }

    .process-card-view-see-more {
        align-items: center;
        display: flex;
        position: relative;
        margin-top: 36px;
        // margin-bottom: 60px;
    }

    .link-see-more {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        color: #18a0fb;
        margin: auto;
    }
}
