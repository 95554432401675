#footer {
    .footer-text {
        /* Inline text elements/p */
        // max-width: 339px;
        width: 100%;
        height: 24px;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 21px;
        /* or 175% */
        letter-spacing: 0.2px;
        color: #8997a2;
    }

    .footer-container {
        align-items: center;
        display: flex;
        position: relative;
        width: 100%;
        height: 147px;
        background: #FFFFFF;

        .footer-content {
            margin: auto;
            height: 67px;
            text-align: center;
        }

        &.smallFooter {
            height: initial;
            margin: 30px auto;

            .footer-content {
                height: initial;
            }
        }
    }

    .link {
        display: inline-block;
        color: #8997a2;

        &:hover {
            color: #18a0fb;
        }
    }
}
