#process-card {
  .process-card-description {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 345px;
    background: #ffffff;
    border: 1px solid #f1f3f4;
    box-sizing: border-box;
    border-radius: 5px;
    flex: none;
    align-self: flex-start;
    margin: auto;
    box-shadow: none;

    hr {
      margin: 0px;
    }

    .process-card-author-container {
      display: flex;
      min-height: 81px;
      justify-content: flex-start;

      .process-card-author-itens {
        display: flex;
        flex-direction: row;
        align-self: center;

        .avatar-box {

          .circular-avatar-card {
            width: 40px !important;
            height: 40px !important;
            background-color: #ffffff;
            display: block;
            margin: auto;

            &.avatar {
              padding: 9px;
              border: 1px solid #34434e;
              border-radius: 50%;

              .Streamline_Icon {
                line-height: 20px;
                display: block;
              }
            }

            button {
              background-color: #ffffff;
            }
          }
        }
      }

      .process-card-author-info {

        .author {
          width: 100%; /* Modificado para 100% caso o nome seja muito longo não quebre linha */
          font-size: font-size(14px);
          font-weight: 600;
          margin: 0px;

          a {
            color: $secondary;
          }
        }

        .jobTitle {
          position: static;
          width: 100%; /* Modificado para 100% caso o nome seja muito longo não quebre linha */
          left: 0px;
          top: 22px;

          font-size: font-size(12px);
          color: $gray-500;

          margin: 0px 1vh;
        }
      }
    }

    .process-card-review-container {
      display: flex;
      flex-direction: column;

      .review-main-text {
        font-size: font-size(14px);

        display: flex;
        align-items: center;
      }

      .review {
        display: flex;
        flex-direction: column;
        padding: 0px;
        width: 100%;

        .process-card-review-ratings {
          display: flex;
          padding: 0px;
          position: relative;
          width: 100%;
          align-items: center;
          /* margin: 0px 15px; */
          margin-top: 15px;

          .score-box {
            /* Avaliação */

            /* Auto Layout */
            position: static;
            width: 100%;
            height: 100%;
            left: 0px;
            top: 0px;

            /* Inside Auto Layout */
            display: flex;

            > div {
              margin-bottom: 20px;
            }

            .score-totalscore {
              /* Frame 92 */

              /* Auto Layout */
              align-content: center;
              display: flex;
              flex-direction: column;
              padding: 0px;

              .score {
                /* Heading / h1 */
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                font-size: 40px;
                line-height: 50px;
                /* or 125% */

                /* Dark */

                color: #34434e;

                /* Inside Auto Layout */
                /* order: 0; */
                align-self: center;
              }

              .totalscore {
                /* Inline text element / p */
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;

                /* Dark */
                color: #34434e;

                /* Inside Auto Layout */

                /* flex: none; */
                /* order: 1; */
                align-self: center;
                margin: -12px 0px;
              }
            }

            .stars-and-bars {
              height: 57px;
              display: flex;
              flex-direction: column;
              align-items: flex-end;

              svg {
                width: 66%;
              }

              .star-bar5 {
                display: flex;
                align-items: center;
                max-width: 203px;
                width: 100%;
                height: 9px;
                margin: 1px;

                .stars-container {
                  display: flex;
                  flex-direction: row-reverse;
                  width: 57px;
                  height: 9px;
                  margin: 3px 0px;

                  .stars-image {
                    margin: 0px 3px;
                  }
                }

                .svg-rating-bar {
                  margin-bottom: inherit;
                  margin-left: 10px;
                }
              }
            }
          }
        }
      }

      .info-box {
        display: flex;
        flex-direction: column;

        .simple-text {
          display: flex;
          flex-direction: column;

          .review-text {
            width: 100%;

            font-size: font-size(14px);
            color: $gray-500;
          }
        }
      }
    }
  }

  .edit-button {
    background: $primary;
    border-radius: 5px;
    border: 1px solid transparent;
    font-weight: 500;
    font-size: 0.9rem;
    svg {
      vertical-align: initial;
    }
  }
}
