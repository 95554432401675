#login {

    .login-flex {
        height: 100vh;
    }

    .login-card {
        margin: 0 auto;
        width: 100%;
        text-align: center;

        h2 {
            text-align: center;
        }

        .social-login-buttons {
            box-sizing: border-box;
            margin-bottom: 1rem;

            button {
                position: relative;
                $padding-size: 2px;
                width: 100%;
                height: 46px;
                padding: $padding-size;
                border: none;
                border-radius: 10em;
                color: #ffffff;

                img {
                    position: absolute;
                    top: $padding-size;
                    left: $padding-size;

                    width: 42px;
                    height: 42px;
                    border-radius: 50%;
                    margin-right: 10px;
                }

                & + button {
                    margin-top: 4px;
                }

                &.facebook {
                    background-color: #4267b2;
                }

                &.linkedin {
                    background-color: #0e76a8;
                    img {
                        padding-left: 2px;
                    }
                }

                &.google {
                    background-color: #5284ed;

                    img {
                        background-color: #ffffff;
                        padding: 4px;
                    }
                }
            }
        }

        .line {
            width: 100%;
            border: 1px solid #DCE1E6;
            margin-bottom: 1rem;
        }
    }


    .loader-icon {
        display: block;
        margin: auto;
    }

    @media (min-width: 576px) {
        .social-login-buttons {
            padding: 0px 50px;
        }

        .login-card {
            width: 420px;
        }
    }

    @media (min-width: 768px) {
    }
}
