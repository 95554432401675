#home {
    .navbar-background {
        background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #FBFCFD;
    }

    .processes-background {
        background: #fbfcfd;
    }

    .more-information-background {
        background: linear-gradient(180deg, #fbfcfd 0%, rgba(255, 255, 255, 0) 100%), #ffffff;
    }
}
