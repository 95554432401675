/*------------------------------------
  Tagify
------------------------------------*/

.tagify {
  --tag-bg: unset;
  --tag-hover: unset;
  align-items: center;
  border-color: $tagify-border-color;
  @include border-radius($tagify-border-radius);

  &:hover {
    border-color: $tagify-hover-border-color;
  }

  &.tagify--focus {
    border-color: $tagify-focus-border-color;
  }

  &__tag {
    background-color: $tagify-bg-color;
    @include border-radius($tagify-border-radius);

    &__removeBtn {
      width: $tagify-removebtn-width;
      height: $tagify-removebtn-height;
      line-height: $tagify-removebtn-line-height;

      &:hover {
        background-color: $tagify-removebtn-bg-hover-color;

        + div {
          &::before {
            box-shadow: $tagify-removebtn-hover-box-shadow !important;
          }

          .tagify__tag-text {
            opacity: 1;
          }
        }
      }
    }

    > div {
      color: $tagify-tag-color;
      line-height: $tagify-tag-line-height;
      padding: $tagify-tag-padding-y $tagify-tag-padding-x;
      @include border-radius($tagify-tag-border-radius);

      * {
        display: inline-flex;
        vertical-align: baseline;
      }
    }
  }

  &__input {
    &::before {
      line-height: $tagify-input-pseudo-line-height;
    }

    .tagify__tag {
      > div {
        padding: $tagify-tag-padding-y $tagify-tag-padding-x;
        line-height: $tagify-input-tag-line-height;
      }
    }
  }

  &__dropdown {
    &__menu {
      border: $tagify-dropdown-menu-border-width $tagify-dropdown-menu-border-type $tagify-dropdown-menu-border-color;
      @include border-bottom-right-radius($tagify-dropdown-menu-bottom-radius);
      @include border-bottom-left-radius($tagify-dropdown-menu-bottom-radius);
      margin-top: -1px;
    }

    &__item {
      &--active {
        color: $tagify-dropdown-item-active-color;
        background-color: $tagify-dropdown-item-active-bg-color;
      }
    }
  }

  &.tagify-form-control-list {
    @include border-bottom-right-radius($tagify-form-control-list-bottom-radius);
    @include border-bottom-left-radius($tagify-form-control-list-bottom-radius);
  }
}