$theme-colors: (
  "primary": #18a0fb,
  "secondary": #34434e
);

$primary: #18a0fb;
$secondary: #34434e;

// Alterando o tamanho máximo dos containers do bootstrap
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 1000px, //960px,
  xl: 1200px //1140px
);

// front
@import "assets/front/scss/theme";

// easymde
@import "~easymde/dist/easymde.min.css";

$secondary: theme-color("secondary");

// css global custom
@import "styles/loading";
@import "styles/media-breakpoints";
@import "styles/icon";
@import "styles/typography";

// css dos componentes
@import "pages/pages";
@import "components/components";

// Altera opacidade do placeholder padrão
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 1 !important; /* Firefox */
}

.clickable {
  cursor: pointer;
}


.fit-content {
  width: fit-content;
}