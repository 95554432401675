/* Início experiência de carregamento */

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0
  }

  100% {
    background-position: 468px 0
  }
}

.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  position: relative;
}

img.img-fluid {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  position: relative;
}

#processViewLoader {
  .title {
    max-width: 350px;
    height: 2rem;
  }

  .process-card-loader {
    box-shadow: none;
    background-color: white;
    border: 1px solid #DCE1E6;
    display: grid;
    /* grid-template-rows: 61.2% 27.5% 11.3%; */
    grid-template-rows: 238px 107px 44px;
    cursor: pointer;
    transition: 0.5s;
    border-radius: 5px;
    max-width: 369px;
    width: 100%;
    min-width: 250px;
    height: 385px;
    margin: 15px;
  }

  .main-drop-loader-container {
    margin-left: auto;
  }

  .main-drop-loader {
    font-family: Poppins !important;
    width: 170px;
    height: 36px !important;
    float: right;
    box-sizing: border-box;

    &::before {
      display: none;
    }

    div {
      overflow: visible;
    }
  }

  .loader-footer-container {
    margin: auto auto 9px 0;
  }

  .cardTitle {
    max-width: 70%;
    // width: 70%;
    height: 1rem;
  }

  .cardCategory {
    max-width: 50%;
    height: 1rem;
  }

  .cardStars {
    width: 70px;
    height: 1rem;
  }

  .cardViews {
    width: 70px;
    height: 1rem;
  }

  .loadMore {
    width: 8%;
    height: 1.5rem;
  }
}

#processNavBarLoader {

  .path {
    width: 60%;

    // width: 160px;
    height: 1rem;
    margin-bottom: 0.5rem;
  }

  .title {
    // max-width: 100%;
    width: 80%;
    height: 2rem;
  }

  .counts {
    min-width: 55px;
    max-width: 75px;
    height: 36px;
  }

  .follow {
    width: 86px;
    height: 36px;
  }

  .share {
    width: 139px;
    height: 36px;
  }
}

#carouselLoader {
  box-shadow: none;

  .img {
    width: 800px;
    height: 445px;
    // max-height: 445px;
  }


  .circular-button-loader {
    /* Auto Layout */
    display: block;
    flex-direction: row;
    padding: 10px;

    position: static;
    width: 44px;
    height: 44px;
    left: 59px;
    top: 0px;

    /* gray 400 */

    border: 1px solid #DCE1E6;
    box-sizing: border-box;
    border-radius: 28px;
    margin: 0 5px;
  }

  .counts {
    width: 2rem;
    height: 1rem;
  }

  .zoom {
    width: 1.5rem;
    height: 1.5rem;
  }
}

#processCardLoader {
  .process-card-author-info-loader {
    display: flex;
    flex-direction: column;
    padding: 0px;

    position: static;
    width: 197px;
    // min-width: 197px;
    min-height: 41px;
    left: 74px;
    top: 20px;
  }

  .authorName {
    // width: 20%;
    width: 80%;
    height: 1rem;
    margin-bottom: 0.5rem;
  }

  .job {
    width: 60%;
    height: 0.7rem;
  }

  .moreInfo {
    margin-top: 0.5rem;
    width: 25px;
    height: 0.8rem;
  }

  .reviewText {
    width: 30%;
    height: 1rem;
  }

  .starsBarsLoader {
    margin-bottom: 5px;
  }

  .starLoader {
    margin-right: 5px;
  }

  .lastUpdate {
    width: 45%;
    height: 1rem;
  }

  .category {
    width: 40%;
    height: 1rem;
  }

  .innerText {
    width: 30%;
    height: 0.7rem;
  }
}

#descriptionLoader {
  .title {
    width: 20%;
    height: 1.5rem;
    margin-top: 2rem;
  }

  .line1 {
    width: 100%;
    height: 1rem;
    margin-top: 0.3rem
  }

  .line2 {
    width: 90%;
    height: 1rem;
    margin-top: 0.3rem
  }

  .tag-container-loader {
    max-width: 809px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    justify-content: left;

    div {
      margin-right: 5px;
      margin-bottom: 10px;
      font-family: Poppins;
      border-radius: 0;
    }
  }

  .rolesTitle {
    width: 15%;
    height: 1.5rem;
  }

  .roles {
    width: 110px;
    height: 2em;
  }
}

#reviewLoader {
  .header-title {
    width: 70%;
    height: 2rem;
  }

  .form-control-main-drop-loader {
    float: right;

    div {
      color: #9AA1A6;
    }
  }

  .main-drop-loader {
    font-family: Poppins !important;
    width: 170px;
    height: 36px !important;
    float: right;
    box-sizing: border-box;

    &::before {
      display: none;
    }

    div {
      overflow: visible;
    }
  }

  .review-author {
    width: 20%;
    height: 1.5rem;
  }

  .review-stars {
    width: 9%;
    height: 1rem;
  }

  .review-line1 {
    width: 100%;
    height: 1rem;
    margin-top: 0.3rem
  }

  .review-line2 {
    width: 90%;
    height: 1rem;
    margin-top: 0.3rem
  }

  .review-options-container {
    margin-top: 1rem;
  }

  .review-useful {
    width: 25%;
    height: 1rem;
  }

  .review-answer {
    width: 5%;
    height: 1rem;
  }

  .review-see-more {
    width: 25%;
    height: 1rem;
  }
}

/* Fim experiência de carregamento */
