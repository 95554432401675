#generic-carousel {
    .carousel-item {
        height: 60vh;
        background: #f1f3f4;
        color: #34434e;
    }
    img {
        object-fit: fill;
        height: 100%;
    }
}
